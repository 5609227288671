
import forEdit from "@/mixin/forEdit";
import GoodsPickList from "./GoodsPickList.vue";

export default {
    name: 'VoucherEdit',
    mixins: [forEdit],
    components: {
        "goods-picker": GoodsPickList
    },

    data() {
        return {
            PB: {
                id: undefined,  // 编辑才有id
                name: "",       // 券名
                type: 1,        // 使用方式
                value: "",      // 券价值
                showAmount: "", // 显示面值
                timeType: 2,    // 失效方式，1截止日期，2持续日期
                endTime: "",    // 到期日期 对应1
                validTime: "",  // 有效天数 对应2
                remark: "",     // 说明
                color: 1,       // 颜色
                isGive: 0,      // 是否允许转赠
                isReceive: 1, //是否需要领取
                bannerPath: [], // 领取券页banner
                givePath: [], // 分享后呈现效果页

                content: "",    // 备注
                goods: [],      // 可兑换商品
                isUseGive: 0,   // 消费后是否关联赠送其它劵
                voucherList: [],// 关联赠送
                takeEffectTime: '',//生效日期
                shortName: '',
            },
            tempIdList: [],     // 新增时的 PB.goods id集合
            uploadHeaders: {         // 图片上传的请求头
                token: this.$store.state.token
            },
            rules: {
                name: {
                    required: true,
                    message: "请输入名称",
                    trigger: "blur"
                },
                type: {
                    required: true,
                    message: "请选择使用方式",
                    trigger: "blur"
                },
                value: [
                    {
                        required: true,
                        message: "请输入劵价值",
                        trigger: "blur"
                    },
                    {
                        validator (rule, value, cb) {
                            const fine = Number.isInteger(value);
                            if (fine) cb();
                            else cb("请输入整数")
                        }
                    },
                ],
                validTime: {
                    required: true,
                    message: "请输入有效天数, 0为永久有效",
                    trigger: "blur"
                },
                endTime: {
                    required: true,
                    message: "请选择到期日期",
                    trigger: "blur"
                },
                takeEffectTime: {
                    required: true,
                    message: "请选择生效日期",
                    trigger: "blur"
                },
                goods: {
                    required: true,
                    message: "至少要添加一个商品",
                    trigger: "blur"
                }
            },
            couponConfTypeEnum: [],  // 使用方式的选项
            showAddPupop: false,     // 显示商品选择面板
            goodsList: [],           // 可选商品列表
            checkedGoods: [],        // 选择面板中已经勾选的商品
            posting: false,          // 状态: 上传图片
            goodsLoading: false,     // 状态：模糊查询商品名称中
            allVoucher: [],          // 所有券列表
            auth: {
                delete: false
            },
            showSL: false,
        }
    },

    computed: {
        appendTXT () {
            const strategies = {
                1: "元",
                2: "%折扣",
                get 3  () {
                    return this[2]
                },
                get 4() {
                    return this[1]
                },
                get 5() {
                    return this[1]
                },
                get undefined () {
                    return this[1]
                }
            }
            return strategies[this.PB.type]
        }
    },

    methods: {
        openSL() {
            this.showSL = true
        },
        handleSLDialogClose() {
            this.showSL = false
        },
        async fetchData() {
			if (this.loading) return;
			this.loading = true;

            try {
                const {id} = this.PB;
                const mainReq = this.$axios({
                    url: "api/couponConf/getCouponConfInfo",
                    params: { id }
                });
                const configReq = this.$axios({
                    url: "api/couponConf/queryCouponConfAll",
                    params: {
                        searchType: 1
                    }
                });
                const [
                    {value: mainRes},
                    {value: configRes}
                ] = await Promise.allSettled([
                    mainReq,
                    configReq
                ]);
                this.loading = false;
                if (mainRes.code !== 2000 && mainRes.code !== 1003) throw mainRes;

                this.couponConfTypeEnum = mainRes.data.couponConfTypeEnum;
                // 回显
                if (id) {
                    const {tCouponConf} = mainRes.data;
                    const { name, type, discount, amount, showAmount, remark, timeType, endTime, validTime, color, isGive, bannerPath, givePath, content, isUseGive, isReceive, takeEffectTime, shortName } = tCouponConf;
                    this.PB.shortName = shortName;
                    this.PB.name = name;
                    this.PB.type = type;
                    this.PB.showAmount = showAmount;
                    this.PB.validTime = validTime;
                    this.PB.timeType = timeType;
                    this.PB.endTime = endTime;
                    this.PB.remark = remark;
                    this.PB.takeEffectTime = takeEffectTime;
                    this.PB.content = content || "";
                    if (color != undefined) this.PB.color = color;
                    if (isGive != undefined) this.PB.isGive = isGive;
                    if (isReceive != undefined) this.PB.isReceive = isReceive;

                    if (bannerPath) this.PB.bannerPath = [{
                        name: bannerPath.slice(bannerPath.lastIndexOf("/")+1),
                        url: this.$baseURL + bannerPath,
                        path: bannerPath
                    }];
                    if (givePath) this.PB.givePath = [{
                        name: givePath.slice(givePath.lastIndexOf("/") + 1),
                        url: this.$baseURL + givePath,
                        path: givePath
                    }];


                    if (isUseGive != undefined) this.PB.isUseGive = isUseGive;
                    if (type == 1 || type == 4 || type == 5) this.PB.value = amount;
                    else if (type == 2 || type == 3) this.PB.value = discount;

                    // 获取商品列表
                    this.fetchGoodsList()

                    // todo 回显关联券
                    if (mainRes.data.tCouponGives) {
                        this.PB.voucherList = mainRes.data.tCouponGives.map(it => {
                            it.worth = parseFloat(it.worth)
                            return it;
                        })
                    }
                }

                if (configRes.code !== 2000 && configRes.code !== 1003) throw configRes;
                this.allVoucher = configRes.data.couponConfAll;

            } catch (reason) {
                console.warn("获取券信息失败", reason);
                this.$message({
                    message: reason.msg || "获取券信息失败",
                    type: "warning"
                });
                this.loading = false;
            }
        },

        // 获取券关联的商品
        fetchGoodsList (couponConfGoodsIds) {
            if (this.loading) return;
			this.loading = true;
            const {id} = this.PB;
			this.$axios({
				url: "api/couponConf/queryCouponConfGoodsList",
				params: { 
                    couponConfId: id,
                    couponConfGoodsIds: id ? undefined : couponConfGoodsIds, 
                }
			})
            .then(res => {
                if (res.code === 2000) {
                    this.loading = false;
                    this.PB.goods.splice(0, this.PB.goods.length,
                        ...res.data.couponConfGoodsList)

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取关联商品失败", reason);
                this.$message({
                    message: reason.msg || "获取关联商品失败",
                    type: "warning"
                });
                this.loading = false
            })
        },

        handleExceed() {
          this.$message({
            message: "只能上传一张图片",
            type: "error",
          });
        },

        beforeFileUpload(file) {
            const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 5;
        
            if (!isImage) {
                this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            if (isImage && isLt2M) {
                this.PB.bannerPath.push(file)
                return true;
            }
            return false;
        },
        beforegiveFileUpload(file) {
            const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 5;

            if (!isImage) {
                this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            if (isImage && isLt2M) {
                this.PB.givePath.push(file)
                return true;
            }
            return false;
        },



        
        handleProgress(e, file) {
            this.posting = true;
            const index = this.PB.bannerPath.indexOf(file);
            this.$set(this.PB.bannerPath, index, file)
        },
        handlegiveProgress(e, file) {
            this.posting = true;
            const index = this.PB.givePath.indexOf(file);
            this.$set(this.PB.givePath, index, file)
        },

        handleUploadSuccess (res, file) {
            this.posting = false;
            const index = this.PB.bannerPath.indexOf(file);
            if (res.code === 2000) {

                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.PB.bannerPath, index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
                this.$refs.form.clearValidate("bannerPath")
            } else {
                this.PB.bannerPath.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },
        handleUploadgiveSuccess(res, file) {
            this.posting = false;
            const index = this.PB.givePath.indexOf(file);
            if (res.code === 2000) {

                const { oldName, filePath } = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.PB.givePath, index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
                this.$refs.form.clearValidate("givePath")
            } else {
                this.PB.givePath.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },
        // 删除文件
        handleIcongiveDelete(index) {
            this.PB.givePath.splice(index, 1);
        },

        // 删除文件
        handleIconDelete (index) {
            this.PB.bannerPath.splice(index, 1);
        },

        // 点击添加商品按钮
        handleAddBtn () {
            this.showAddPupop = true;
        },
        handleDialogClose () {
            this.showAddPupop = false;
            this.checkedGoods.splice(0, this.checkedGoods.length);
        },

        // 勾选、取消勾选时
        handleGoodsPicker(goods) {
            this.checkedGoods.splice(0, this.checkedGoods.length, ...goods);
        },

        // 点击确定选择
        handConfirmButton () {
            if (!this.checkedGoods.length) return this.$message({
                message: "请选择商品",
                type: "error"
            });
            if (this.checkedGoods.some(g => !g.isSingle && !g.checked.length))
            return this.$message({
                message: "请选择商品规格",
                type: "error"
            });
            this.loading = true;
            return this.$oxios({
                url: "api/couponConf/saveCouponConfGoods",
                method: "post",
                params: {
                    couponConfGoodsStr: JSON.stringify(this.checkedGoods.map(item => {
                        const checkedList = item.checked.map(id => item.specs.find(s =>
                            s.detailid == id)).filter(Boolean)
                        return {
                            couponconfid: this.PB.id,
                            goodsid: item.id,
                            skuVal : item.isSingle ? "" : checkedList.map(it => it.sku_val).join()
                        }
                    }))
                }
            })
            .then(res => {
                if (res.code === 2000) {
                    this.loading = false;
                    const ids = res.data.confGoodsIdList;
                    this.tempIdList.push(...ids);
                    this.fetchGoodsList(this.tempIdList.join())
                    this.handleDialogClose();
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.log("保存失败", reason)
                this.loading = false
            })
        },

        // 移除一个可兑换商品
        handleGoodsRemove(index) {
            this.$confirm("要移除这个商品吗")
            .then(() => {
                this.loading = true;
                const item = this.PB.goods[index];
                return this.$axios({
                    url: "api/couponConf/deleteCouponConfGoods",
                    method: "post",
                    data: {
                        couponConfGoodsId: item.id
                    }
                })
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    })
                    this.PB.goods.splice(index, 1);
                }
                else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                this.$message({
                    message: reason.msg || "删除失败",
                    type: "error"
                });
                console.log("删除失败", reason)
            })
            .then(() => this.loading = false)
        },

        // 商品框输入名称模糊查询商品列表
        queryLikeGoods (str) {
            function doSearch () {
                this.goodsLoading = true;
                this.timer = null;
                this.$axios({
                    url: "api/goods/queryLikeGoods",
                    params: {goodsName: str}
                })
                .then(res => {
                    if (res.code === 2000) {
                        const {goodsList, PB: {goodsId}} = this;
                        goodsList.splice(0, goodsList.length, ...res.data.goodsList)

                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: reason.msg || "获取商品失败",
                        type: "warning"
                    })
                })
                .then(() => {
                    this.goodsLoading = false;
                })
            }

            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(doSearch.bind(this), 500)
        },

        handleAddVoucherBtn () {
            this.PB.voucherList.push(this.createNewVoucherLine())
        },

        createNewVoucherLine () {
            return {
                confid: this.PB.id,
                giveconfid: "",
                worth: 0,
                num: 1
            }
        },

        onVoucherSelected (vid, index) {
            const item = this.PB.voucherList[index];
            const voucher = this.allVoucher.find(({id}) => id === vid);

            if (!item || !voucher) {
                this.$set(this.PB.voucherList, index, this.createNewVoucherLine())
            }
            else {
                item.worth = voucher.amount
            }
        },

        handleVoucherRemove (index) {
            this.PB.voucherList.splice(index, 1)
        },

        // 点击提交按钮
        handleSubmitButton () {

            this.$refs.form.validate()

            .then(() => {
                this.posting = true;
                const { id, name, type, value, showAmount, timeType, validTime, endTime, remark, content, goods, color, isGive, bannerPath, givePath, isUseGive, voucherList, isReceive, takeEffectTime, shortName } = this.PB;
                const data = { name, type, showAmount, timeType, remark, content, color, isGive, isUseGive, isReceive, takeEffectTime, shortName };
                // 抵扣券传amount, 折扣券传discount
                if (type == 1 || type == 4 || type == 5) data.amount = value;
                else if (type == 2 || type == 3) data.discount = value;
                if (timeType == 1) data.endTime = endTime;
                else if (timeType == 2) data.validTime = validTime;
                if (isUseGive) data.couponGiveStr = JSON.stringify(voucherList)
                else data.couponGiveStr = "";
                // 有id传id（编辑）
                if (id) data.id = id;
                else {
                    data.couponConfGoodsIdStr = goods.map(it => it.id).join();
                }
                data.bannerPath = bannerPath.map(item => item.path).join()
                data.givePath = givePath.map(item => item.path).join()
                return this.$axios({
                    url: "api/couponConf/saveCouponConf",
                    method: "post",
                    data
                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "保存成功" : res.msg || "保存失败",
                    type: success ? "success" : "warn"
                });

                if (success) {
                    this.$router.back()
                }
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: "保存失败",
                    type: "error"
                });
                console.warn(reason);
            })

            .then(() => {
                this.posting = false
            })
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        }
    },

    created () {
        this.auth.delete = this.$hasAuthFor("api/couponConf/deleteCouponConfGoods");
        // 有 id 的情况，在 @/mixin/forEdit 已经处理了，
        // 这个没有 id 也发送请求的目的是获取 岗位、系统 的配置信息
        const id = Number(this.$route.params.id);
        if (!id) {
            this.fetchData();
        }
    },
}