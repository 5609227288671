<template>
    <div>
        <div class="set--bottom-foot-padding">
            <!-- 搜索 -->
            <ul class="tips">
                <!-- <li>积分扣除可能原因：购买提名房积分减半，有优惠积分减半，优惠超过1%无积分，结算渠道费的/员工亲戚购房的无积分，超过预计交割日期的扣积分，退单扣回积分。</li> -->
            </ul>
            <div >
                <el-row :gutter="12" class="flex a-center">
                    
                    <el-col :span="6">
                        <div class="flex a-center">
                            <div class="" style="flex-shrink: 0;">操作方式：</div>
                            <el-select class='setplicecolor' v-model="searchType" placeholder="请选择" multiple collapse-tags>
                                <el-option
                                :disabled="item.disabled"
                                v-for="item in billTypeEnum"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="flex a-center">
                            <div class="">备注：</div>
                            <el-input maxlength="20" style="width: 218px;" placeholder="请输入" v-model.trim="searchRemark"></el-input>
                        </div>
                        
                    </el-col>
                    <el-col :span="12">
                        <div class="flex a-center">
                            <div class="">操作端：</div>
                            <el-select v-model="searchSys" placeholder="请选择">
                                <el-option
                                v-for="item in billSysEnum"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                                </el-option>
                            </el-select>
                            <div class="flex a-center" style="margin-left:20px;">
                                <el-button  type="primary" size="mini" @click="onSearch">搜索</el-button>
                                <el-button  size="mini" @click="clearSearch">清空</el-button>
                                 <!-- 导出按钮 -->
                                <el-button size="mini" icon="icon-export" v-if="auth.downCustBillDetail" @click.stop="handleExportBtn" class="ml3">导出明细</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <el-table :data="billlist" empty-text="暂无记录" v-loading="loading" @expand-change="handleExpandChange" class="srsgifttable">
                <!-- 折叠详情 -->
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div>
                            <el-form label-position="left" label-suffix=":" inline class="table-expand">
                                <el-form-item  label="关联单号" style="width:calc(30% - 50px);">
                                    <el-button @click="handleOrderDetail(props.row)"
                                        class="cell-list" type="text">
                                        {{props.row.order_sn}}
                                    </el-button>
                                </el-form-item>
                                <el-form-item  label="交易账号" style="width:calc(30% - 50px);">
                                    <div v-if="props.row.real_code||props.row.real_phone">
                                        <span>{{props.row.real_code}}</span>
                                        <span v-show="props.row.real_code&&props.row.real_phone"> - </span>
                                        <span>{{props.row.real_phone}}</span>
                                    </div>
                                    <span v-else>当前账号</span>
                                </el-form-item>
                                <el-form-item  label="操作端" style="width:calc(30% - 50px);">
                                    <span>{{props.row.sys}}</span>
                                </el-form-item>
                                <el-form-item  label="附件" style="width:calc(30% - 50px);">
                                    <thumbnail-list :images="props.row.pictures"></thumbnail-list>
                                </el-form-item>
                            </el-form>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="类型" prop="type" min-width="120">
                    <template slot-scope="scope">
                        <div>
                            {{scope.row.type||''}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="变更积分" prop="money" min-width="120">
                    <template slot-scope="scope">
                        <div>
                            {{scope.row.money||0}}
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="等级积分" prop="vip_money" min-width="120">
                    <template slot-scope="scope">
                        <div>
                            {{scope.row.vip_money||0}}
                        </div>
                    </template>
                </el-table-column> -->
                <el-table-column label="冻结积分" prop="lock_money" min-width="120">
                    <template slot-scope="scope">
                        <div>
                            {{scope.row.lock_money||0}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="办理业务费用（澳币）" prop="contractMoney" min-width="130">
                    <template slot-scope="scope">
                        <div>
                            {{NUMERAL(scope.row.contractMoney)||0}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="时间" prop="create_time" min-width="120">
                    <template slot-scope="scope">
                        <span v-html="scope.row.create_time"></span>
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="change_desc" min-width="350"></el-table-column>
            </el-table>
        </div>
            
        <!-- list-foot-bar 默认插入元素（分页组件）会被插入右侧，
        slot="left" 会插入左侧（预想为列表选中状态） -->
        <list-foot-bar>
            <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                :current-page.sync="SP.pageNum"
                :page-size="SP.pageSize"
                :total="total"
                @size-change="handlePageSizeChange"
                @current-change="handlePageNoChange"
            ></el-pagination>
        </list-foot-bar>
    </div>
</template>

<script>
import { Loading } from "element-ui";
import forList from "@/mixin/forList";
export default {
    name: 'SystemGiftList',
    mixins: [ forList],
    noSaveState: true,

    props: {
        id: {
            required: true,
            type: Number
        },
        billSysEnum: {
            type: Array,
            default: () => []
        },
        billTypeEnum: {
            type: Array,
            default: () => []
        },
        
        
    },

    data () {
        return {
            billlist: [],
            searchRemark:'',
            searchSys:-1,
             expandRowKeys: [],  // 展开所有
            expandAll: false,
            searchType:[]
        }
    },
    
     created () {
        
        this.auth.downCustBillDetail = this.$hasAuthFor("api/billDetail/downCustBillDetail");
    },
    methods: {
        //详情
        handleOrderDetail(row) {
            console.log(row);
            this.$router.push(`/order/list/${null}/${row.order_sn}`);
        },
        handleExportBtn () {
            // 检查有无时间条件，没有的话不能导出
            // const {startTime, endTime} = this.SP_;
            // if (!(startTime || endTime)) {
            //     this.handleAdvancedSearchBtn();
            //     return setTimeout(() => this.$message({
            //         message: "请选择导出的时间范围",
            //         type: "error"
            //     }), 100);
            // }
            this.$confirm("确认导出当前条件下的积分明细数据吗？")
            .then(() => {
                this.operating = true
                const params ={
                    custId:this.id,
                    searchType:this.searchType.join(),
                    searchSys:this.searchSys,
                    searchRemark:this.searchRemark,
                }
                return this.$oxios({
                    url: "api/billDetail/downCustBillDetail",
                    method: "post",
                    params:params
                    // data: {
                    //     content: JSON.stringify(params)
                    // }
                })
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    this.closeAdvanceSearch();
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                // this.$confirm拒绝结果是值 cancel
                if (reason === "cancel") {
                    console.log("取消了")
                } else {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                console.warn("导出失败", reason);
            })
            .then(() => this.operating = false)


        },
        createRowKey(e) {
            return e.id;
        },
        handleExpandChange(row, expandList) {
            if (expandList.length === this.tableData.length) this.expandAll = true;
            else if (expandList.length === 0) this.expandAll = false;
        },
        handleExpandAll() {
            this.expandAll = !this.expandAll;
            if (this.expandAll) {
                this.expandRowKeys = this.tableData.map(it => it.id)
            } else {
                this.expandRowKeys = []
            }
        },
         clearSearch(){
            this.searchRemark = ''
            this.searchSys = -1
            this.searchType = []
            this.fetchData(true)
        },
        onSearch(){
            this.fetchData(true)
        },
        fetchData (flag) {
            if (this.loading) return;
            this.loading = true;
            if(flag){
                this.loading = true;
            }

            this.$axios("api/billDetail/getListByCusId", {
                params: { ...this.extractParams(), custId: this.id, type: 0 ,searchRemark:this.searchRemark,searchSys:this.searchSys,searchType:this.searchType.join()}
            })
            
            .then(res => {
                if (res.code === 2000) {
                    this.total = res.data.total;
                    this.billlist = res.data.list.map(item => {
                        // 根据 up_down 将积分转换为正负数
                        item.money && (item.money = item.up_down == 2 ? -item.money : "+" + item.money);
                        // item.vip_money && (item.vip_money = item.up_down == 2 ? -item.vip_money : "+" + item.vip_money);
                        item.empname = item.empname || "系统";
                        if (item.pictures) {
                            item.pictures = item.pictures.split(",").map(
                                path => {
                                    return this.$baseURL + path
                                })
                        } else {
                            item.pictures = []
                        }

                        return item;
                    })
                    this.loading = false;

                } else if (res.code !== 1003) {
                    this.loading = false;
                    this.$message({
                        message: res.msg || "获取积分明细失败，请稍后重试",
                        type: "warning"
                    })
                }
            })
        },

        // 分页组件发生 每页数据条数 改变事件
        handlePageSizeChange(e) {
            this.SP.pageSize = e;
            this.SP.pageNum = 1;
            this.fetchData();
        },

        // 分页组件发生 当前页 改变事件
        handlePageNoChange(e) {
            this.SP.pageNum = e;
            this.fetchData();
        },
    }
}
</script>

<style lang='scss' scoped>
.tips {
        position: relative;
        font-size: 14px;
        line-height: 1.6;
        color: #FF6666;
        margin-bottom: 16px;
    }
.flex{
    display: flex;
}
.a-center{
    align-items: center;
}
    .table-expand {
    font-size: 16px;
    .el-form-item {
        // width: 40%;
        margin-bottom: 0;
        margin-right: 48px;
    }
    /deep/ .el-form-item__label {
        color: #323233 !important;
        font-weight: 600;
    }
    .expandremark {
        display: inline-flex;
        align-items: center;
    }
     .expandremark /deep/ .el-form-item__label{
        flex-shrink: 0;
    }
    .expandremark /deep/ .el-form-item__content{
        line-height: initial;
    }
}
    
</style>

<style>
    .srsgifttable .el-table__expanded-cell{
        padding-left: 20px !important;
    }
    
    
    
    

</style>