<template>
    <el-form class="private as-page scroll-wrapper"
        :model="PB"
        :rules="rules"
        label-width="150px"
        label-suffix=": "
        ref="form"
    >
        
        <el-form-item label="客户名称">
            <el-input :value="profile.name" disabled class="width2"></el-input>
        </el-form-item>

        <el-form-item label="电话号码">
            <el-input :value="secretPhone" disabled class="width2"></el-input>
        </el-form-item>

        <el-form-item label="积分" prop="money">
            <el-input v-model="PB.money" type="number" class="width2"></el-input>
            <span class="hint" style="color: darkorange">扣回积分，输入负数即可</span>
        </el-form-item>

        <!-- <el-form-item label="是否影响会员等级" required prop="isVip">
            <el-radio-group v-model="PB.isVip">
                <el-radio
                    v-for="item in yesOrNot"
                    :key="item.value"
                    :label="item.value"
                >{{item.label}}</el-radio>
            </el-radio-group>
        </el-form-item> -->

        <el-form-item label="附件" class="wrap">
            <el-upload
                class="upload-row"
                :action="$baseURL + 'api/common/fileSingleUpLoad'"
                :headers="uploadHeaders"
                :limit="5"
                :on-success="handleUploadSuccess"
                :file-list="PB.pictures"
                :show-file-list="false"
                :on-exceed="handleExceed"
                :before-upload="beforeFileUpload"
                :on-progress="handleProgress"
            >
                <el-button size="small">点击上传</el-button>
                <div slot="tip" class="upload-tip">最多上传5张图</div>
            </el-upload>

            <thumbnail-list :images="PB.pictures" :show-delete="true"
                @delete="handleIconDelete"></thumbnail-list>
        </el-form-item>

        <el-form-item label="备注">
            <el-input v-model="PB.changeDesc" maxlength="500" type="textarea" rows="4" class="width2"></el-input>
        </el-form-item>

        <el-form-item>
            <el-button type="primary" @click="handleSubmitButton" :disabled="submitDisabled && uploading">保存</el-button>
        </el-form-item>

    </el-form>
</template>

<script>
import forEdit from "@/mixin/forEdit";
const yesOrNot = [
    {label: "是", value: 0},
    {label: "否", value: 1}
];
export default {
    name: 'RechargeForm',
    mixins: [forEdit],
    props: {
        profile: {
            required: true,
            type: Object
        }
    },

    computed: {
        secretPhone () {
            return this.profile.phone && this.profile.phone.slice(0, 3) + "****" + this.profile.phone.slice(7)
        }
    },

    data () {
        return {
            yesOrNot,
            PB: {
                money: "", // 充值积分，输入为负数时表示扣回积分，保存时需要二次确认提示
                isVip: "", // 0是1否
                pictures: [], // 附件 url
                changeDesc: "", // 备注
            },
            rules: {
                money: {
                    required: true,
                    message: "请输入充值数量",
                    trigger: "blur"
                },
                // isVip: {
                //     validator (r, v, c) {
                //         if (typeof v !== "number")
                //             c("请选择是否影响会员等级");
                //         else c()
                //     }
                // }
            },
            uploadHeaders: {         // 图片上传的请求头
                token: this.$store.state.token
            },
            uploading: false,        // 状态：正在上传图片

        }
    },

    methods: {
        // 提交充值
        handleSubmitButton () {
            this.$refs.form.validate()
            .then(() => {
                const p = this.PB.money;
                if (p === "" || p == null) {
                    return Promise.reject("没有填写积分")
                }
                const tips = p < 0 ? 
                    `充值积分为负值，您确认扣回客户${Math.abs(p)}积分么` :
                    `确认充值${p}积分吗？`;

                return this.$confirm(tips, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                  })
            })

            .then(() => {
                this.posting = true;
                const { id, isVip, pictures, money, changeDesc } = this.PB;
                return this.$axios({
                    url: "api/billDetail/backRecharge",
                    method: "post",
                    data: {
                        custid: id,
                        isVip,
                        money: Number(money),
                        pictures: pictures.map(item => item.path || "").join(),
                        changeDesc,
                    }

                })
            })

            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "积分充值成功" : res.msg || "积分充值失败",
                    type: success ? "success" : "warn"
                });

                if (success) {
                    this.resetPB();
                    this.$emit("recharged")
                }
            })
            
            .catch(reason => {
                if (reason && reason !== "cancel") this.$message({
                    message: "操作失败",
                    type: "error"
                });
                console.warn("取消充值，或充值失败", reason);
            })
            
            .then(() => {
                this.posting = false
            })

        },

        handleExceed() {
          this.$message({
            message: "只能上传五张图片",
            type: "error",
          });
        },

        beforeFileUpload(file) {
          const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
          const isLt2M = file.size / 1024 / 1024 < 5;
    
          if (!isImage) {
            this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 5MB!');
          }
          if (isImage && isLt2M) {
            this.PB.pictures.push(file)
            return true;
          }
          return false;
        },
        
        handleProgress(e, file) {
            this.uploading = true;
            const index = this.PB.pictures.indexOf(file);
            this.$set(this.PB.pictures, index, file)
        },

        // 上传文件成功
        handleUploadSuccess (res, file) {
            this.uploading = false;
            const index = this.PB.pictures.indexOf(file);
            if (res.code === 2000) {
                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.PB.pictures, index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
            } else {
                this.PB.pictures.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },

        // 删除文件
        handleIconDelete (index) {
            this.PB.pictures.splice(index, 1);
        },

        // 重置 充值表单
        resetPB () {

            this.PB.money = this.PB.changeDesc = "";
            this.PB.pictures.splice(0, this.PB.pictures.length)
        }
    }
}
</script>

<style lang='scss' scoped>

    .table-thrmbnails {
        display: inline-block;
        width: 46px;
        height: 46px;
        background-size: cover;
        border-radius: 2px;
        cursor: pointer;
    }
    
</style>