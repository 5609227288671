import forList from "@/mixin/forList";

export default {
    name: "CustBusinessCouponList",
    mixins: [forList],

    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                seachCustCode: '',
                searchSubNo: "",
            },
            couponNameAllList: [],
            numMapList: [],
            numMapsuccAllList: [],
            numMapfailAllList: [],
            expands: [],
            updateChildTable: false,//刷新表格专用
            hasSearchParam:false,//是否有搜索条件
        };
    },

    computed: {
    },

    methods: {
        tableHeaderColor({ row, column, rowIndex, columnIndex }) {
            console.log(row, column, rowIndex, columnIndex);
            console.log((row.length - 2) / 2 + 2);
            if (columnIndex >= ((row.length - 2) / 2 + 2)) {
                return 'color:#909399;font-weight:500;'; //蓝色
            } else {
                return 'font-weight:500;'; //蓝色
            }
        },

        handleExpandChange(row, expandedRows) {
            console.log(row, expandedRows);
            this.loadDetails(row)
            // if (this.expands.includes(row.id)) {
            //     this.expands = this.expands.filter(id => id !== row.id);
            // } else {
            //     // this.loadDetails(row)
            //     this.expands = [row.id];
            // }
        },
        // 展开详情
        handleRowClick(row, column, event) {
            // 通过判断是否已经展开来切换详情行的展开状态
            // console.log(column);

            if (column.label == '操作') {
                if (this.expands.includes(row.id)) {
                    this.expands = this.expands.filter(id => id !== row.id);
                } else {
                    this.loadDetails(row)
                    this.expands = [row.id];
                }
            }

        },
        // 获取详情
        async loadDetails(row, callback) {
            row.isLoading = true
            const res = await this.$axios({
                url: "api/custBusinessCoupon/queryCustBusinessCouponLog",
                params: { custBusSubId: row.id, }
            })

            if (res.code === 2000) {
                row.details = res.data.custBusinessCouponLogList
            }
            row.isLoading = false
            this.updateChildTable = !this.updateChildTable
        },

        //详情
        handleClick(row) {

        },



        async fetchData() {
            this.numMapsuccAllList = []
            this.numMapfailAllList = []


            if (this.loading) return;
            this.loading = true;
            const res = await this.$axios({
                url: "api/custBusinessCoupon/queryCustBusinessCouponList",
                params: { ...this.extractParams() }
            })

            if (res.code === 2000) {
                if(this.SP_.seachCustCode||this.SP_.searchSubNo){ //有搜索条件显示
                    this.hasSearchParam = true
                }else{
                    this.hasSearchParam = false
                }

                this.total = res.data.count;
                this.tableData = res.data.allMapList;
                this.tableData.forEach(element => {
                    element.details = []
                    element.isLoading = false
                });
                this.couponNameAllList = res.data.couponNameAllList;
                this.expands = []
            }
            // 业务券统计
            const resnum = await this.$axios({
                url: "api/custBusinessCoupon/queryCustBusinessCouponNum",
                params: { seachCustCode: this.SP_.seachCustCode, searchSubNo: this.SP_.searchSubNo }
            })

            if (resnum.code === 2000) {
                this.loading = false;
                this.numMapList = resnum.data.numMapList || [];
                // 成功的
                this.numMapsuccAllList = resnum.data.numMapList.filter((item) => item.succAllNum > 0);
                // 失败的
                this.numMapfailAllList = resnum.data.numMapList.filter((item) => item.failAllNum > 0);
            }
        },
    },

    created() {
    }
};