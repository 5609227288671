import axios from 'axios';
import { Loading } from 'element-ui';
import forList from "@/mixin/forList";
import {createIssueData, rules} from "./batchIssue";
import ViewParams from "./ViewParams.vue";
import Phones from "@/components/Phones.vue";

import UpdateRegion from "./UpdateRegion.vue";
// 生成一个随机数字 默认6位数
function createNumber (count = 6) {
    return Math.floor(Math.random()*(10**count))
}
function setTreeProps (list) {
    list.forEach(item => {
        if (item.childList) item.children = setTreeProps(item.childList);
        if (item.sysId) item.disabled = true;
        item.label = item.areaname || item.sysName
    });
    return list;
}

export default {
    name: 'UserList',
    mixins: [forList],
    components: { ViewParams, UpdateRegion, Phones },

    data () {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {                       // 存储高级搜索面板的临时的查询参数
                fuzzyQueryType: 0,
                fuzzyQueryValue: "",
                startIntegral: "",
                endIntegral: "",
                startTime: "",
                endTime: "",
                sysType: "",           // 系统
                areaId: "",            // 区域
                type: "",              // 初始来源
                levelId: "",           // 积分等级
                sysSysTypes: [],           // 是否购房客户
                status: "",            // 状态
                firstLogin: "",        // 登录状态
                isFans: "",            // 是否公众号粉丝
            },
            totalCountLoading: false,  // 总条数获取中
            // 表格项（行）的操作菜单
            actionList: [
                {
                    label: "详情",
                    name: "profile"
                },
                {
                    label: "系统赠送明细",
                    name: "system"
                },
                {
                    label: "积分充值",
                    name: "recharge"
                }
            ],
            // #endregion ----------

            // #region 配置参数 ----------
            customerSearchTypeList:[], // 客户列表查询下拉集合
            sysTypeList:[],            // 系统集合
            areaList: [],              // 区域集合
            customerSourceList:[],     // 客户初始来源
            customerStatusList:[],     // 客户状态集合
            customerLoginList:[],      // 客户登陆状态集合
            isFansList: [],            // 是否关注公众号集合
            levelList: [],             // 积分等级集合
            whetherZeroList: [],       // 其他是否集合
            sysList: [],               // 系统区域树
            systemTypeEnum: [], //成交客户系统
            // #endregion
            
            issueData: null,    // 批量发放优惠券数据
            issueRules: rules,  // 批量发放验证
            couponOpts: [],     // 优惠券选择列表
            sampleFileUrl: this.$baseURL + "upload/winktemp/custcoupontemp.xlsx",

            showNotice: false,  // 积分规则介绍窗的显示状态
            operating: false,   // 操作数据中：删除、导出等执行时（通信中）
            pageFlag: 0,        // 保存一个随机数字，在获取列表数量前随机生成，在请返回时验证是否是最新一次请求
			detailData: null,
            updatingRegionItem: null,
            phoneList: [],
            activeName: 'zero'
        }
    },

    computed: {
        currentCustomerSearchType () {
            return this.customerSearchTypeList.find(item => item.value === this.SP_.fuzzyQueryType)
        },

        buttonFreezing () {
            return this.loading || this.operating
        },
        
        bulkFreezing () {
            return this.buttonFreezing || !this.checkedlist.length
        },

        singleSysChecked () {
            return this.SP_.sysType.length === 1
        }
    },

    methods: {
        async querySearchThree(queryString, cb, type) {
            try {
                const res = await this.$axios({
                    url: "/api/giftActivity/queryCouponGiftActivity",
                    method: "post",
                    data: {
                        searchName: queryString,
                    }
                });

                if (res.code === 2000) {
                    cb(res.data.list.map(emp => {
                        emp.value = emp.actName + ' - ' + emp.realilyCustNum
                        return emp;
                    }));

                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
            }
        },
        handleAutocompleteChangeChannel(e, key) {
            if (e.trim() !== "") return;
            this.issueData.actName = "";
            this.issueData.actId = "";
        },
        handleSelectChannel(item, key) {
            console.log(item);
            this.issueData.actName = item.actName;
            this.issueData.actId = item.id + ''

        },
        handleClick() {

        },
        //查看电话号码
        handlePhoneParam(row) {
            this.loading = true;
            let _phoneList = []
            this.$axios({
                url: "api/customer/queryAllPhone",
                params: {
                    id: row.id
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        for (const key in res.data.data) {
                            if (res.data.data[key]) {
                                _phoneList.push(res.data.data[key])
                            }
                        }
                        this.phoneList = _phoneList
                        this.loading = false;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: "获取电话号码失败",
                        type: "error"
                    })
                    console.warn(reason)
                    this.loading = false;
                })
        },
        // 从缓存中恢复页面查询参数后，需要后续执行的函数（见 @/mixin/clearListPageStateOnCreated）
        callBackForListState (params) {
            // console.log("从缓存中恢复的状态", params)
            if (params.sysType)
            this.handleSysTypeChange(params.sysType)
        },

        // 获取列表数据
        async fetchData() {
            if (this.loading) return;
            this.loading = true;
            this.totalCountLoading = true;
            const params = this.extractParams(); // 格式化查询参数
            // #region 请求列表数据 ==========
            this.$axios({
                url: "api/customer/getList",
                params
            })
            .then(res => {
                if (res.code === 2000) {
                    const list = res.data?.customerList;
                    list && this.tableData.splice(0, this.tableData.length, ...list);

                    const couponConfAll = res.data?.couponConfAll;
                    if (couponConfAll) this.couponOpts = couponConfAll.map(it => {
                        if (it.name.length > 21) it.shortName = it.name.slice(0, 19) + "...";
                        return it;
                    });
                    this.loading = false;
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                this.$message({
                    message: "获取用户列表失败",
                    type: "error"
                })
                console.warn(reason)
                this.loading = false;
            })
            // #endregion ==========


            // #region 请求分页数据 ==========
            delete params.pageSize; delete params.pageNum;
            const pageFlag = createNumber();
            this.pageFlag = pageFlag;
            // this.total = 0;
            params.pageFlag = pageFlag;
            this.$axios({
                url: "api/customer/getCount",
                params
            })
            .then(res => {
                if (res.code === 2000) {
                    if (res.data.pageFlag === this.pageFlag)
                        this.total = res.data?.customerCount;
                    else console.log("获取到了数量, 但已经不是最新的请求了, 所以舍弃");
                } else if (res.code !== 1003) {
                    console.warn("获取总数失败")
                }
                this.totalCountLoading = false;
            })
            .catch(reason => {
                console.warn(reason)
                this.totalCountLoading = false;
            })

            // #endregion ==========
        },

        // 获取配置配置参数
        async fetchConfig() {
            const res = await this.$axios({ url: "api/customer/getListCond" })

            if (res.code === 2000) {
                // 把获取的配置列表设为 data 中对应的配置列表
                for (let key in res.data) {
                    if (key == "sysList") {
                        this[key] = setTreeProps(res.data[key])
                    }
                    else this[key] = res.data[key]
                }
            } else if (res.code !== 1003) {
                console.warn("获取参数失败")
            }
            this.systemTypeEnum.map(item => {
                item.name += '成交'
            })
        },

        // 查看详细参数
        handleInParam(index) {
            const item = this.tableData[index];
            this.detailData = item.areaNamelist
        },


        handleAction (action, scope) {
            const type = action.name;
            const data = scope.row;
            this.$router.push(`/user/user/${data.id || 5}/${type}`);
        },

        // 批量删除
        async handleBulkDelete () {
            const sure = await this.$confirm("确定要删除选中的客户吗", '提示', {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            });
            if (!sure) return;

            const ids = this.checkedlist.map(item => item.id).join()

            const res = await this.$axios({
                url: "api/customer/del",
                method: "post",
                data: { ids }
            });

            const success = 2000 == res.code;
            this.$message({
                message: success ? "批量删除客户成功" : (res.msg || "批量删除客户失败"),
                type: success ? "success" : "warn"
            });
            success && this.fetchData()
        },

        // 批量关注
        async handleBulkFollow () {
            const sure = await this.$confirm(`您已选择${this.checkedlist.length}个客户, 确认全部标记为已关注公众号么?`, '提示', {
                confirmButtonText: '确认标记',
                cancelButtonText: '取消',
                type: 'warning'
            });
            if (!sure) return;

            const ids = this.checkedlist.map(item => item.id).join()

            const res = await this.$axios({
                url: "api/customer/connWeiXin",
                method: "post",
                data: { ids }
            });

            const success = 2000 == res.code;
            this.$message({
                message: success ? "批量关注客户成功" : (res.msg || "批量关注客户失败"),
                type: success ? "success" : "warn"
            });
            // success && this.fetchData()
            this.$refs.table.clearSelection();
        },

        // 批量发放优惠券
        handleIssueBtn () {
            this.issueData = createIssueData();
        },
        // 批量变更区域
        handleUpdateRegionBtn () {
            this.updatingRegionItem = this.checkedlist;
        },
        handleIssueClose () {
            this.issueData = null;
        },
        handleBulkIssue () {
            let loadingRef;
            this.$refs.issue.validate()
            .then(() => {
                loadingRef = Loading.service({
                    target: ".el-dialog"
                })
                const data = new FormData();
                console.log(this.issueData);
                const { cusfile, couponConfId, remark, type, actId } = this.issueData;

                if (this.issueData.type == 1) {
                    data.append("cusfile", null);
                    data.append("actId", actId);

                } else {
                    data.append("cusfile", cusfile[0]);
                    data.append("actId", 0);
                }
                data.append("couponConfId", couponConfId);
                data.append("remark", remark);
                data.append("type", type);
                return axios.post(this.$baseURL+"api/custCoupon/batchGiftCustCoupon", data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        token: this.$store.state.token
                    }
                })
            })
            .then(res => {
                if (res.data.code === 2000) {
                    // this.$message({
                    //     message: "发放成功",
                    //     type: "success"
                    // })
                    this.$alert(res.data.msg || "发放成功", "批量发放结果");
                    this.issueData = null;
                    loadingRef && loadingRef.close();

                } else if (res.data?.code === 1003) {
                    this.$store.commit("clearUserInfo");
                    this.$message({
                        message: "登录过期，请重新登录",
                        type: "warning"
                    });
                    return Promise.reject(false);

                } else return Promise.reject(res.data);
            })
            .catch (reason => {
                console.warn("上传失败", reason);
                if (reason !== false) {
                    this.$message({
                        message: reason?.msg || "发放失败",
                        type: "error"
                    })
                }
                loadingRef && loadingRef.close();
            })
        },
        handleExceed () {
            this.$message({
                message: "只能上传一个表格",
                type: "error"
            })
        },
        handleSelectFile (e) {
            this.issueData.cusfile.push(e.raw)
        },
        handleRemoveFile (e) {
            const index = this.issueData.cusfile.indexOf(e);
            this.issueData.cusfile.splice(index, 1)
        },

        // 点击导出
        handleExportBtn () {
            // 检查有无时间条件，没有的话不能导出
            const {startTime, endTime} = this.SP_;
            if (!(startTime || endTime)) {
                this.handleAdvancedSearchBtn();
                return setTimeout(() => this.$message({
                    message: "请选择导出的时间范围",
                    type: "error"
                }), 100);
            }

            this.$confirm("确认导出当前条件下的用户列表数据吗？")

            .then(() => {
                this.operating = true
                const params = this.extractParams(true);
                return this.$oxios({
                    url: "api/download/exportCsrList",
                    method: "post",
                    data: {
                        content: JSON.stringify(params)
                    }
                })
            })

            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    this.closeAdvanceSearch();
                } else if (res.code !== 1003) return Promise.reject(res)
            })

            .catch(reason => {
                // this.$confirm拒绝结果是值 cancel
                if (reason === "cancel") {
                    console.log("取消了")
                } else {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                console.warn("导出失败", reason);
            })
            .then(() => this.operating = false)


        },

        // 选择系统后，区域重置
        handleSysTypeChange (val) {
            this.SP_.areaId = "";
            if (val.length === 1) { // 只有选择了单个系统才能选择索索区域
                // 根据系统查询所属的区域
                this.$axios({
                    url: "api/customer/getRegionsBySysType",
                    params: {
                        sysType: val[0]
                    }
                }).then(res => {
                    if (res.code === 2000) {
                        this.areaList = res.data.arealist.map(item => ({
                            ...item,
                            name: item.otherAreaName,
                            value: item.areaId
                        }));
                    } else if (res.code !== 1003) return Promise.reject(res)
                }).catch(reason => {
                    console.warn(reason);
                })
            }
        },

        // 点击积分规则按钮
        handleNoticeSwitch () {
            this.showNotice = !this.showNotice
        }
    },

    created() {
        this.fetchConfig();
		this.auth.export = this.$hasAuthFor("api/download/exportCsrList");
		this.auth.detail = this.$hasAuthFor("api/customer/getCustInfo");
		this.auth.delete = this.$hasAuthFor("api/customer/del");
		this.auth.follow = this.$hasAuthFor("api/customer/connWeiXin");
		this.auth.issue = this.$hasAuthFor("api/custCoupon/batchGiftCustCoupon");
		this.auth.updateRegion = this.$hasAuthFor("api/customer/updateCustArea");
        this.auth.seePhone = this.$hasAuthFor("/api/customer/queryAllPhone");
    }
}