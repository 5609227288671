import forList from "@/mixin/forList";

export default {
    name: "BouponBusinessConf",
    mixins: [forList],

    data() {
        return {
            loading: false,
            couponBusinessConfDia: false,
            clickRow: '',
            couponOpts: [],
            couponConfId: '',
            itemList: [],
            chooseitem: '',
            rechargeRatio: '',
        };
    },

    methods: {
        // 获取列表数据
        fetchData() {
            if (this.loading) return;
            this.loading = true;
            this.$axios({
                url: "api/couponConf/queryCouponBusinessConfList",
            })
                .then(res => {
                    if (res.code === 2000) {
                        this.tableData = res.data.couponBusicnessConfList
                        this.rechargeRatio = res.data.rechargeRatio.split(':')



                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    console.warn("获取业务权益配置失败", reason);
                    this.$message({
                        message: reason.msg || "获取业务权益配置失败",
                        type: "warning"
                    });
                })
                .then(() => this.loading = false)
        },
        // 获取劵
        getcouponOpts() {
            return this.$axios("api/custCoupon/queryCustCouponList", {
                params: {
                    // vipNo: this.profile.vipNo,
                    // searchValue: this.searchValue,
                    searchState: 1,
                    searchDrawState: -1,
                    // searchCouponType: this.searchCouponType,
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        const { couponConfAll } = res.data;
                        this.couponOpts = couponConfAll.map(it => {
                            if (it.name.length > 21) it.shortName = it.name.slice(0, 19) + "...";
                            return it;
                        });
                    } else if (res.code !== 1003) {
                        this.$message({
                            message: res.msg || "获取客户卡劵失败，请稍后重试",
                            type: "warning"
                        })
                    }
                })
        },
        addItem() {
            console.log(this.chooseitem);
            if (this.chooseitem) {
                let hasitem = this.itemList.find(item => item.couponConfId == this.chooseitem.id)
                if (hasitem) {
                    this.$message({
                        message: "不能重复添加",
                        type: "warning"
                    })
                    return
                }
                this.itemList.push(
                    {
                        couponConfId: this.chooseitem.id,
                        name: this.chooseitem.name,
                        amount: this.chooseitem.amount,
                        num: 1
                    }
                )
                this.couponConfId = ''
                this.chooseitem = ''
            }
        },
        delItem(index) {
            this.$confirm("确定要删除吗")
                .then(() => {
                    this.itemList.splice(index, 1)
                })
        },
        //打开弹窗
        openCouponBusinessConf(row) {
            this.clickRow = row
            let _arr = []
            row.couponInfoList.forEach(element => {
                _arr.push(
                    {
                        couponConfId: element.confId,
                        name: element.name,
                        amount: element.amount,
                        num: element.num
                    }
                )
            });
            this.itemList = _arr
            this.couponBusinessConfDia = true;
        },
        // 关闭弹窗
        closeCouponBusinessConfDia() {
            this.couponBusinessConfDia = false;
        },
        // 保存
        saveCouponBusinessConfDia() {
            if (this.itemList.length == 0) {
                this.$message({
                    message: "请添加权益劵",
                    type: "warning"
                })
                return
            }
            const loadingRef = this.$loading({
                target: ".edit .el-dialog"
            });

            let confDetailList = []
            this.itemList.forEach(element => {
                confDetailList.push({
                    couponConfId: element.couponConfId,
                    num: element.num,
                })
            });

            return this.$axios({
                url: "api/couponConf/saveCouponBusinessConf",
                method: "post",
                data: {
                    busId: this.clickRow.id,
                    confDetailListStr: JSON.stringify(confDetailList)
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        this.$message({
                            message: "保存成功",
                            type: "success"
                        });
                        this.couponBusinessConfDia = false;
                        loadingRef && loadingRef.close();
                        this.fetchData()
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    loadingRef && loadingRef.close();
                    console.warn(reason);
                    this.$message({
                        message: reason.msg || "保存失败，请稍后重试",
                        type: "warning"
                    })
                })
                .then(() => { loadingRef && loadingRef.close(); })


        },
        // 选择劵类型
        handleCouponConfChange(val, recovery) {
            console.log(val, recovery)
            if (val) {
                let chooseitem = this.couponOpts.find(item => item.id == val)
                this.chooseitem = chooseitem

            }
        },
        handleNumChange(index) {
            this.itemList[index].num = Number(this.itemList[index].num.replace(/[^\d]/g, "")); //清除数字以外的字符   
        },
    },

    created() {
        this.auth.save = this.$hasAuthFor("api/couponConf/saveCouponBusinessConf");
        this.getcouponOpts()
    }
};