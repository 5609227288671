import { render, staticRenderFns } from "./scoreLogs.vue?vue&type=template&id=89e7890c&scoped=true"
import script from "./scoreLogs.vue?vue&type=script&lang=js"
export * from "./scoreLogs.vue?vue&type=script&lang=js"
import style0 from "./scoreLogs.vue?vue&type=style&index=0&id=89e7890c&prod&lang=scss&scoped=true"
import style1 from "./scoreLogs.vue?vue&type=style&index=1&id=89e7890c&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89e7890c",
  null
  
)

export default component.exports