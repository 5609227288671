<template>
  <div  class="address-list page list-page">
    <div class="page--list" v-loading="loading">
        <div class="scroll-wrapper pd4 set--bottom-foot-padding">
            <list-tool-bar>
                <el-button v-if="auth.add" icon="el-icon-plus" type="primary"
                    @click.stop="handleAddBtn">添加</el-button>
                <el-button v-if="auth.batch"  :disabled="!multipleSelection.length" type="primary"
                    @click.stop="handleBatch">批量是否隐藏</el-button>
            </list-tool-bar>
            <list-search-bar @search.stop="handleSearch" 
            @clear.stop="handleClearBtn" :inline="true">

                <el-form-item label="省市区">
                    <el-select clearable @change="(e)=>{handleSelectChange(e,3)}" v-model="SP_.searchProvince" placeholder="请选择">
                        <el-option
                        v-for="item in provinceOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-select clearable  @change="(e)=>{handleSelectChange(e,4)}" v-model="SP_.searchCity" placeholder="请选择">
                        <el-option
                        v-for="item in cityOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-select clearable v-model="SP_.searchArea" placeholder="请选择">
                        <el-option
                        v-for="item in areaOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否隐藏">
                    <el-select clearable v-model="SP_.searchState" placeholder="请选择">
                        <el-option
                        v-for="item in isHiddenList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

            </list-search-bar>
            <el-table  :data="tableData" @selection-change="handleSelectionChange">
                <el-table-column type="selection"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="父级" prop="fullPaths"></el-table-column>
                <el-table-column label="是否隐藏" prop="stateName"></el-table-column>
                <el-table-column label="操作" fixed="right">
                    <template slot-scope="scope">
                        <el-button 
                            v-if="auth.edit"
                            type="text"
                            @click.stop="handleEdit(scope.$index, scope.row)"
                            >编辑</el-button
                        >
                        <el-button 
                            v-if="auth.delete"
                            type="text"
                            @click.stop="handleDelete(scope.$index, scope.row)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>


        </div>
        <list-foot-bar>
            <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                :current-page.sync="SP.pageNum"
                :page-size="SP.pageSize"
                :total="total"
                @size-change="handlePageSizeChange"
                @current-change="handlePageNoChange"
            ></el-pagination>
        </list-foot-bar>
        <!-- 新增弹窗 -->
        <el-dialog
            :visible="showAddPupop"
            @close="handleDialogClose"
            title="新增"
            width="500px">
            <el-form class="private" 
                :model="PB"
                :rules="rules"
                label-width="100px"
                label-suffix=":"
                v-if="showAddPupop"
                ref="form"
            >
                <!-- 省份 -->
                <el-form-item label="上级" prop="">
                    <el-select @change="(e)=>{handleSelectChange(e,3)}" v-model="PB.province" placeholder="请选择">
                        <el-option
                        v-for="item in provinceOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- 市级 -->
                <el-form-item label="" prop="">
                    <el-select  @change="(e)=>{handleSelectChange(e,4)}" v-model="PB.city" placeholder="请选择">
                        <el-option
                        v-for="item in cityOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- 区域 -->
                <el-form-item label="" prop="">
                    <el-select v-model="PB.area" placeholder="请选择">
                        <el-option
                        v-for="item in areaOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!--  -->
                <el-form-item label="名称" prop="name">
                    <el-input v-model="PB.name" placeholder="请填写名称"  clearable />
                    
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="handleDialogClose">取消</el-button>
                <el-button @click="handSubmitButton" type="primary" :disabled="submitDisabled">保存</el-button>
            </div>

        </el-dialog>
        <!-- 编辑弹窗 -->
        <el-dialog
            :visible="showEditPupop"
            @close="handleDialogClose"
            title="编辑"
            width="500px">
            <el-form class="private" 
                :model="editform"
                label-width="100px"
                label-suffix=":"
                ref="editform"
                :rules="rules"
            >
                <el-form-item label="上级" prop="fullPaths">
                    <el-input v-model="editform.fullPaths" disabled placeholder="请填写名称"  clearable />
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="editform.name" placeholder="请填写名称"  clearable />
                </el-form-item>
                
            </el-form>
            <div slot="footer">
                <el-button @click="handleEditDialogClose">取消</el-button>
                <el-button @click="handEditSubmitButton" type="primary">保存</el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="提示"
            :visible.sync="batchDialogVisible"
            width="30%"
            center>
            <div class="dialog-body" style="text-align: center;">
                <el-radio-group v-model="batchRadio">
                    <el-radio :label="0">隐藏</el-radio>
                    <el-radio :label="1">显示</el-radio>
                </el-radio-group>
            </div>
            
            <span slot="footer" class="dialog-footer">
                <el-button @click="batchDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveBatchHandle">确 定</el-button>
            </span>
        </el-dialog>

        
    </div>
  </div>
</template>

<script>
import forList from "@/mixin/forList";
import forEdit from "@/mixin/forEdit";
    // 函数创建一个空的菜单数据
    const initialPB = () => ({
        province: "",//省
        city: "", //市
        area: "",//区
        name:'',//名称
    });
    export default {
        mixins: [forList, forEdit],
        data() {
            return {
                loading: false,
                list: [],
                provinceOptions:[],
                cityOptions:[],
                areaOptions:[],
                value: '',
                isHiddenList:[
                    {
                        value: 0,
                        label: '是'
                    },
                    {
                        value: 1,
                        label: '否'
                    },
                ],
                 /**
                 * SP_是临时保存用户界面输入的查询参数的数据
                 * 真正的列表查询数据保存在SP中（见@mixin/forList）
                 * SP_数据只有在用户点击搜索后，才会复制到SP
                 */
                SP_: {
                    searchProvince: "", //省
                    searchCity:'',
                    searchArea:'',
                    searchState:''//搜索状态 1是否 0是是
                },
                tableData:[],
                multipleSelection: [],
                
                PB: null, // POST BODY: 当前对话框编辑的数据
                rules: {
                    name: {
                        required: true,
                        trigger: "blur",
                        message: "请填写名称"
                    },
                },
                showEditPupop: false,
                editform:{
                    name:'',
                    fullPaths:'',
                    id:''
                },
                batchDialogVisible:false,//批量操作隐藏显示的弹窗
                batchRadio:0,
            }
        },
        computed: {
            // 对话框状态由 PB 是否有值产生
            showAddPupop () {
                return !!this.PB
            },
        },
        methods: {
            initialPB, // 创建并返回一个新的空的菜单数据的方法

            // 点击 "新增菜单"
            handleAddBtn() {
                this.initSelectData();
                this.cityOptions = [];
                this.areaOptions = [];

                this.PB = this.initialPB();
            },
            // 关闭对话框
            handleDialogClose() {
                this.SP_.searchProvince = ''
                this.SP_.searchCity = ''
                this.SP_.searchArea = ''

                this.cityOptions = [];
                this.areaOptions = [];
                this.PB = null
            },
            handSubmitButton() {
                this.$refs.form.validate().then(() => {
                    let parentid = 0;
                    console.log(this.PB)
                    if(this.PB.province){
                        
                        parentid = this.PB.province
                    }
                    if(this.PB.city){
                        
                        parentid = this.PB.city
                    }
                    if(this.PB.area){
                        
                        parentid = this.PB.area
                    }
                   

                    this.$axios({
                        url: "api/areaCity/saveAreaCity",
                        method: "post",
                        data: {
                            parentid,
                            name: this.PB.name
                        }
                    }).then(res => {
                        console.log("结果",res)
                        if (res.code === 2000) {
                            this.$message({
                                message: "保存成功",
                                type: "success"
                            });
                            this.handleDialogClose();
                            this.fetchData();
                        }
                    })
                })
            },
            handleSelectChange(value,type){
                if(type == 3){
                    this.SP_.searchCity = ''
                    this.SP_.searchArea = '';
                    if(this.PB){
                        this.PB.city = ''
                        this.PB.area = ''
                    }
                }
                
                
                if(type == 4){
                    this.SP_.searchArea = '';
                    if(this.PB){
                        this.PB.area = ''
                    }
                }
                
                this.initSelectData(type,value)
              
            },
            handleBatch(){
                if(this.multipleSelection.length){
                    this.batchRadio = 0;
                    this.batchDialogVisible = true;
                    console.log(this.multipleSelection)
                }
               

            },
            saveBatchHandle(){
                this.$axios({
                    url: "api/areaCity/bachUpdateState",
                    method: "post",
                    data: {
                        ids:this.multipleSelection.map(item=>item.id).join(','),
                        state:this.batchRadio
                    }
                }).then(res => {
                    if (res.code === 2000) {
                        this.$message({
                            message: "操作成功",
                            type: "success"
                        })
                        this.batchDialogVisible = false;
                        this.fetchData();
                    }
                })

            },
            handleSelectionChange(e){
                console.log(e)
                this.multipleSelection = e
            },
            initSelectData(searchLevel=2,searchParentId=null){
                this.$axios({
                    url: "api/areaCity/queryAreaCityByLevel",
                    method: "post",
                    data: {
                        searchLevel:searchLevel == 2 ? 2 :'',
                        searchParentId
                    }
                }).then(res => {
                    if(searchLevel === 2) this.provinceOptions = res.data.data;
                    if(searchLevel === 3) this.cityOptions = res.data.data;
                    if(searchLevel === 4) this.areaOptions = res.data.data;
                })
                
            },
            async fetchData() {
                if (this.loading) return;
                this.loading = true;

                this.$axios({
                    url: "api/areaCity/queryAreaCityList",
                    method: "post",
                    data: {
                        ...this.SP_,
                        ...this.SP
                    }
                }).then(res => {
                    this.initSelectData();
                        if (res.code === 2000 && res.data.areaCityList) {
                            this.total = res.data.count;
                            this.tableData = res.data.areaCityList

                        } else if (res.code !== 1003) return Promise.reject(res)
                }).catch(reason => {
                    console.warn("获取数据失败", reason);
                    this.$message({
                        message: reason.msg || "获取数据失败",
                        type: "warning"
                    });
                }).then(() => this.loading = false)
            },
            // 点击表格行操作按钮: 删除
            async handleDelete(index) {
                const data = this.tableData[index];
                // let levelText = ''
                // if(data.level === 1){
                //     levelText = '省份(一级)'
                // }else if(data.level === 2){
                //     levelText = '城市(二级)'
                // }else{
                //     levelText = '区(三级)'
                // }
                const res = await this.$axios({
                        url: "/api/areaCity/queryAreaCityNum",
                        method: "post",
                        data: {
                            id: data.id
                        }
                })
                console.log("res",res)


                let delText =  `${data.fullPaths}：${data.name}，当前删除项有${res.data.num - 1}个下级，是否确认删除？`
                this.$confirm(delText).then(() => {





                    this.$axios({
                        url: "api/areaCity/deleteAreaCity",
                        method: "post",
                        data: {
                            id: data.id
                        }
                    }).then(res => {
                        if (res.code === 2000) {
                            this.$message({
                                message: "删除成功",
                                type: "success"
                            });
                            this.fetchData();
                        }
                    
                    })
                })
            },
            // 编辑
            handleEdit(index){
                

                this.showEditPupop = true
                this.$nextTick(()=>{
                    this.$refs.editform.resetFields()
                    const data = this.tableData[index];
                    this.editform.fullPaths = data.fullPaths
                    this.editform.name = data.name
                    this.editform.id = data.id
                })
               
                

            },
            handleEditDialogClose(){
                this.showEditPupop = false
            },
            handEditSubmitButton(){
                this.$refs.editform.validate().then(() => {
                    

                    this.$axios({
                        url: "api/areaCity/saveAreaCity",
                        method: "post",
                        data: {
                       
                            name: this.editform.name,
                            id:this.editform.id
                        }
                    }).then(res => {
                        console.log("结果",res)
                        if (res.code === 2000) {
                            this.$message({
                                message: "保存成功",
                                type: "success"
                            });
                            this.handleEditDialogClose();
                            this.fetchData();
                        }
                    })
                
                })
            }

        },
        created() {
            this.auth.add = this.$hasAuthFor("/api/areaCity/saveAreaCity");
            this.auth.delete = this.$hasAuthFor("/api/areaCity/deleteAreaCity");
            this.auth.edit = this.$hasAuthFor("/api/areaCity/saveAreaCity");
            this.auth.batch = this.$hasAuthFor("/api/areaCity/bachUpdateState");
        }
    }
</script>

<style>

</style>