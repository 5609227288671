import StepsBar from "@/components/StepsBar";
import Step1 from "./Step1/Step1.vue";
import Step2 from "./Step2/Step2.vue";
import Step3 from "./Step3/Step3.vue";

export default {
    name: 'GoodsAdd',
    components: {
        "steps-bar": StepsBar,
        "step1-con": Step1,
        "step2-con": Step2,
        "step3-con": Step3
    },

    data () {
        return {
            id: undefined, // 商品id，新增商品时无

            steps: ["基础信息", "商品规格", "商品配图"], // 步骤列表
            currenStepNum: 1,                          // 当前步骤

            // step1Data: {},  // 步骤1的数据
            // step2Data: {},  // 步骤2的数据
            // step3Data: {},  // 步骤3的数据

            loading: false, // 请求状态: 获取
            posting: false, // 请求状态: 保存
            auth: {},       // 用户权限，见created
        }
    },

    computed: {
        // 页面状态：新增商品（!编辑商品）
        isAddPage () {
            return !this.id
        },

        // 当前激活的 step 组件
        currenStep () {
            return this.$refs[`step${this.currenStepNum}`]
        },

        // 当前在第一步
        isFirstStep () {
            return this.currenStepNum === 1
        },

        // 当前在最后一步
        isLastStep () {
            return this.currenStepNum === this.steps.length
        },

        // 当前在中间步骤
        isMediumStep () {
            return !(this.isFirstStep || this.isLastStep)
        }
    },

    methods: {

        // 获取商品信息（编辑商品时）
        async fetchData(id) {
            id = id || this.id;
            if (!id) return;
            this.loading = true;

            try {
                const res = await this.$get(`api/goods/queryUpdateInfo?id=${id}`);
                if (res.code === 2000) {
                    res.data.detailList.forEach(element => {
                        if (element.detail.isDeal == 1) {
                            element.detail.isDeal = true
                        } else {
                            element.detail.isDeal = false
                        }

                        if (element.detail.checked == 1) {
                            element.detail.checked = true
                        } else {
                            element.detail.checked = false
                        }
                    });
                    const {detailList, goods, specsList, preCategoryId} = res.data;
                    console.log(detailList);
                    goods.categoryId1 = preCategoryId || goods.preCategoryId;
                    this.$refs.step1.setData(goods);
                    this.$refs.step2.setData(goods);
                    this.$refs.step2.handleCategory1(goods.categoryId1, true);
                    // setTimeout(() => { // 设置父id时，子id被清空了，这里重新设置一下
                    //     this.$refs.step2.setData({
                    //         categoryId: goods.categoryId
                    //     })
                    // }, 1e3);
                    this.$refs.step3.setData(goods);

                    await this.$refs.step2.setExistData(specsList, detailList);
                    this.$refs.step2.getSpecsByCategory(goods.categoryId, true, detailList);
                    this.loading = false

                } else if (res.code !==1003) throw new Error(res)

            } catch (reason) {
                console.warn(reason);
                this.loading = false;
                this.$message({
                    message: "获取商品信息失败，请稍候再试",
                    type: "warning"
                })
                this.navigateBack()
                
            }
        },

        // 获取配置参数
        // fetchConfig() {},

        // 步骤发起更新数据的请求
        handleStepDataChange (data, num) {
            // const component = this.$refs[`step${num}`];
            const model = this[`step${num}Data`];
            copyProperty(data, model);

            // 深拷贝一个对象的属性到另一个对象
            function copyProperty (origin, target = {}) {
                for (let key in origin) {
                    const value = origin[key];
                    if (typeof value === "object") {
                        if (value.constructor !== target[key].constructor) {
                            target[key] = new value.constructor();
                        }
                        copyProperty(value, target[key])
                    } else {
                        target[key] = value
                    }
                }
                return target;
            }
        },

        // 验证步骤是否填写正确
        validateStep (step) {
            step = step || this.currenStep;
            return step.validate();
        },

        // 点击下一步
        async handleNextBtn () {
            try {
                await this.validateStep();
                if (this.currenStepNum == 1) {
                    const stepData = this.currenStep.getData();
                    this.$refs.step2.setData({
                        name: stepData.name
                    })
                    // let cur = this.currenStepNum++;
                    // while (cur < 4) {
                    //     this.$refs[`step${cur}`].setData(stepData);
                    //     cur++
                    // }

                }
                this.currenStepNum++;

            } catch (reason) {
                console.warn(reason)

                // 未通过验证会抛出 fasle
                if (!reason) {
                    this.$message({
                        message: "请填写完整再进入下一步",
                        type: "warning"
                    })
                }
            } 
        },

        // 点击上一步
        handlePreviousBtn () {
            this.currenStepNum--;
        },
        validateMoren() {
            let pb = this.formatPostBody();
            if (pb.detailList.length > 0 && pb.goods.isType == 2) {
                let findindex = pb.detailList.findIndex(element => {
                    return element.checked
                });
                console.log(findindex);
                if (findindex > -1) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }

        },

        // 点击保存
        async handleSubmitBtn () {
            try {
                await this.validateStep();
                if (!this.validateMoren()) {
                    this.$message({
                        message: "请勾选商品“默认选中”规格",
                        type: "warning"
                    })
                    return
                }
                this.posting = true;
                const pb = this.formatPostBody();
                const res = await this.$oxios({
                    url: "api/goods/saveGoods",
                    method: "post",
                    data: {
                        goodsdata: pb
                    }
                });

                if (res.code === 2000) {
                    this.posting = false;
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    if (this.isAddPage) this.$router.back();
                    
                } else if (res.code !== 1003) {
                    return Promise.reject(res)
                }

            } catch (reason) {
                this.$message({
                    message: reason.msg || "请根据提示完善商品信息",
                    type: "error"
                });
                console.warn("不能保存数据". reason);
                this.posting = false;
            }
        },

        // 格式化请求数据
        formatPostBody () {
            let data;
            if (!this.isAddPage) {
                const stepData = this.currenStep.getData();
                data = {
                    goods: {...stepData},
                    detailList: stepData.detailList || []
                };

            } else {
                const step1Data = this.$refs.step1.getData()
                const step2Data = this.$refs.step2.getData()
                const step3Data = this.$refs.step3.getData()
    
                data = {
                    goods: Object.assign({}, step1Data, step2Data, step3Data),
                    detailList: step2Data.detailList
                };
            }
            // data.goods.isInner = 1
            delete data.goods.detailList;
            return data;
        },

        navigateBack () {
            this.$router.back();
        }
    },

    created () {
        const id = Number(this.$route.params.id);
        if (id) {
            this.id = id;
            this.fetchData(id);
        }
		this.auth.save = this.$hasAuthFor("api/goods/saveGoods");

        // this.fetchConfig()
    // },
    
    // mounted () {
    //     setTimeout(() => { // 测试
    //         this.$refs.step2.setData({
    //             categoryId: 8
    //         })
    //     }, 2e3)
    }
}