import { render, staticRenderFns } from "./SpecsCheckor.vue?vue&type=template&id=3f413656&scoped=true"
import script from "./SpecsCheckor.vue?vue&type=script&lang=js"
export * from "./SpecsCheckor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f413656",
  null
  
)

export default component.exports